import React, { useEffect } from 'react';
import './Profile.css';
import { useNavigate } from 'react-router-dom';

function Profile({ username, did, onLogout }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!username) {
      onLogout();
    }
  }, [username]);

  return (
    <div className="profile-wrapper">
      <h1 className='main-tittle' >Profile</h1>
      <div className="profile-container">
        <div className="profile-info">
          <p><strong>Username:</strong> {username}</p>
          <p><strong>DID: </strong><a href={`https://explorer.tanika.ai/search/${did}`} target="_blank" rel="noopener noreferrer">View</a></p>
        </div>
      </div>
      <div className="footer">
        <hr className="footer-divider" />
        <p className="footer-infor">© 2024 ⎈+V</p>
      </div>
    </div>
  );
}

export default Profile;
