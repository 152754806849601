import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ListAgents.css';

function ListAgents({ onLogout }) {
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [agentsPerPage] = useState(10);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [loadingState, setLoadingState] = useState({});
  const getModelName = (modelId) => {
    switch (modelId) {
      case "1":
        return 'ChatGPT 3.5';
      case "2":
        return 'ChatGPT 4o';
      default:
        return 'Unknown Model';
    }
  };

  const serviceMap = {
    "1": "Family Services",
    "2": "Social Services",
    "3": "Healthcare",
    "4": "Education",
    "5": "Various (depends on the company)",
    "6": "Mental Health Services",
    "7": "Legal Services",
    "8": "Financial Services",
    "9": "Community Services",
    "10": "Religious Organizations",
    "11": "Fitness and Wellness",
    "12": "Personal Care Services",
    "13": "Healthcare (Dental)",
    "14": "Automotive Services",
    "15": "Real Estate/Property Management",
    "16": "Retail",
    "17": "Postal Services",
    "18": "Library Services/Information Management",
    "19": "Engineering",
    "20": "Arts and Entertainment",
    "21": "Sports and Athletics",
    "22": "Food Services/Hospitality",
    "23": "Law Enforcement",
    "24": "Emergency Services",
    "25": "Accounting/Financial Services",
    "26": "Scientific Research",
    "27": "Publishing/Media",
    "28": "Business/Entrepreneurship",
    "29": "Sales",
    "30": "Government/Politics",
    "31": "Media/Journalism",
    "32": "Agriculture",
    "33": "Automotive/Mechanical Services",
    "34": "Aviation/Transportation",
    "35": "Psychology",
    "36": "Architecture/Construction",
    "99": "General"
  };

  const getServiceName = (serviceId) => {
      return serviceMap[serviceId] || 'Unknown Service';
  };

  const roleMap = {
    "1": "Family member (e.g., parents, siblings, spouse, children)",
    "2": "Friend",
    "3": "Doctor",
    "4": "Nurse",
    "5": "Teacher/Professor",
    "6": "Boss",
    "7": "Colleague",
    "8": "Therapist/Counselor",
    "9": "Lawyer",
    "10": "Financial advisor",
    "11": "Neighbor",
    "12": "Boyfriend",
    "13": "Girlfriend",
    "14": "Mentor",
    "15": "Religious leader (if applicable)",
    "16": "Coach/Personal trainer",
    "17": "Hairdresser/Barber",
    "18": "Dentist",
    "19": "Mechanic",
    "20": "Landlord/Property manager",
    "21": "Grocery store clerk",
    "22": "Postal worker",
    "23": "Librarian",
    "24": "Engineer",
    "25": "Artist",
    "26": "Actor/Actress",
    "27": "Musician",
    "28": "Athlete",
    "29": "Chef",
    "30": "Police Officer",
    "31": "Firefighter",
    "32": "Accountant",
    "33": "Scientist",
    "34": "Writer",
    "35": "Entrepreneur",
    "36": "Angel Investor",
    "37": "Venture Capital Investor",
    "38": "Salesperson",
    "39": "Politician",
    "40": "Journalist",
    "41": "Farmer",
    "42": "Mechanic",
    "43": "Pilot",
    "44": "Psychologist",
    "45": "Architect"
  };

  const getRoleName = (roleId) => {
      return roleMap[roleId] || 'Unknown Role';
  };

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/list_agents`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAgents(response.data);
      } catch (error) {
        console.error('Error fetching agents', error);
        onLogout();
      }
    };
    fetchAgents();
  }, [API_URL, onLogout]);

  const handleChat = (agentId, agentName, model, service, description, role) => {
	const serviceName = getServiceName(service);  // Convert serviceId to service name
    const roleName = getRoleName(role);  // Convert roleId to role name
    navigate(`/chat/${agentId}`, { state: { agentName, serviceName, model, description, roleName } });
  };

  const handleCreateWallet = async (agentId) => {
    setLoadingState((prevState) => ({ ...prevState, [agentId]: true }));
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/create_wallet`,
        { agent_id: agentId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Refresh the agent list if needed
      const agentsResponse = await axios.get(`${API_URL}/list_agents`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAgents(agentsResponse.data);

    } catch (error) {
      console.error('Error creating wallet', error);
    } finally {
      setLoadingState((prevState) => ({ ...prevState, [agentId]: false }));
    }
  };

  // Logic for displaying current agents
  const indexOfLastAgent = currentPage * agentsPerPage;
  const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
  const currentAgents = agents.slice(indexOfFirstAgent, indexOfLastAgent);

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(agents.length / agentsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="agent-lib-wrapper">
      <div className='agent-lib-inner'>
        <div>
          <h1 className='main-tittle'>Agent Library</h1>
          <span>
            <p className='text-02'>
              Manage all your AI Agents in one interface.
            </p>
          </span>
          <span>
            <p className='text-02'>
              Problem: AI Agent management <br />
              Status: Demonstrable
            </p>
          </span>

          <hr />
        </div>
        <div className='list-nav'>
          <p className='total-agent'>Total Agents: {agents.length}</p>
        </div>
        <table className='agent-lib-table'>
          <thead className='table-lib'>
            <tr>
              <th>Name</th>
              <th>Model</th>
              <th>Role</th>
              <th>Service</th>
              <th>DID</th>
              <th>Secured ID</th>
              <th>Wallet</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className='body-table'>
            {currentAgents.map((agent) => (
              <tr key={agent.id}>
                <td>{agent.name}</td>
                <td>{getModelName(agent.model)}</td>
                <td>{getRoleName(agent.role)}</td>
                <td>{getServiceName(agent.service)}</td>
                <td><a href={`https://explorer.tanika.ai/search/${agent.did}`} target="_blank" rel="noopener noreferrer">View</a></td>
                <td><a href={`https://explorer.tanika.ai/search/${agent.nft_id}`} target="_blank" rel="noopener noreferrer">View</a></td>
                <td>
                  <a href={`https://explorer.tanika.ai/search/${agent.wallet_address}`} target="_blank" rel="noopener noreferrer" >{agent.amount} TST</a>
                </td>
                <td><button className='chat-agent' onClick={() => handleChat(agent.id, agent.name, agent.model, agent.service, agent.description, agent.role)}>Chat</button></td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav>
          <ul className="pagination">
            {pageNumbers.map(number => (
              <li key={number} className="page-item">
                <a onClick={() => paginate(number)} href="#" className="page-link">
                  {number}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className='footer'>
          <hr className='footer-divider' />
          <p className='footer-infor'>© 2024 ⎈+V</p>
        </div>
      </div>
    </div>
  );
}

export default ListAgents;
