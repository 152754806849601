import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateAgent.css';

function CreateAgent({ onLogout }) {
  const [name, setName] = useState('');
  const [model, setModel] = useState('');
  const [role, setRole] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('')
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/create_agent`,
        { name, model, service, description, role },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMessage(response.data.message);
      navigate('/list-agents');
    } catch (error) {
      setMessage('Error creating agent');
      onLogout();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='create-agent-wrapper'>
      <div>
        <div>
          <h1 className='main-tittle'>Create Agent</h1>
          <span>
            <p className='text-02'>
              Secured and traceable agents are created on this page. Users define the type and objectives their Agents should have.
            </p>
          </span>
          <span>
            <p className='text-02'>
              Problem: Unsecured and Untraceable AI Agents on the open web.<br />
              Status: Demonstrable
            </p>
          </span>
          <hr />
        </div>
        <form className='form-create' onSubmit={handleSubmit}>
          <div className='inner-form-create-agent'>
            <div>
              <select required value={role} onChange={(e) => setRole(e.target.value)}>
                <option value="">– Role –</option>
                <option value="1">Family member (e.g., parents, siblings, spouse, children)</option>
                <option value="2">Friend</option>
                <option value="3">Doctor</option>
                <option value="4">Nurse</option>
                <option value="5">Teacher/Professor</option>
                <option value="6">Boss</option>
                <option value="7">Colleague</option>
                <option value="8">Therapist/Counselor</option>
                <option value="9">Lawyer</option>
                <option value="10">Financial advisor</option>
                <option value="11">Neighbor</option>
                <option value="12">Boyfriend</option>
                <option value="13">Girlfriend</option>
                <option value="14">Mentor</option>
                <option value="15">Religious leader (if applicable)</option>
                <option value="16">Coach/Personal trainer</option>
                <option value="17">Hairdresser/Barber</option>
                <option value="18">Dentist</option>
                <option value="19">Mechanic</option>
                <option value="20">Landlord/Property manager</option>
                <option value="21">Grocery store clerk</option>
                <option value="22">Postal worker</option>
                <option value="23">Librarian</option>
                <option value="24">Engineer</option>
                <option value="25">Artist</option>
                <option value="26">Actor/Actress</option>
                <option value="27">Musician</option>
                <option value="28">Athlete</option>
                <option value="29">Chef</option>
                <option value="30">Police Officer</option>
                <option value="31">Firefighter</option>
                <option value="32">Accountant</option>
                <option value="33">Scientist</option>
                <option value="34">Writer</option>
                <option value="35">Entrepreneur</option>
                <option value="36">Angel Investor</option>
                <option value="37">Venture Capital Investor</option>
                <option value="38">Salesperson</option>
                <option value="39">Politician</option>
                <option value="40">Journalist</option>
                <option value="41">Farmer</option>
                <option value="42">Mechanic</option>
                <option value="43">Pilot</option>
                <option value="44">Psychologist</option>
                <option value="45">Architect</option>
              </select>
            </div>
            <div className='form-create-input'>
              <select required value={service} onChange={(e) => setService(e.target.value)}>
                <option value="">- Objective -</option>
                <option value="1">Education</option>
                <option value="2">Healthcare</option>
                <option value="3">Finance</option>
                <option value="4">Family Services</option>
                <option value="5">Social Services</option>
                <option value="6">Various (depends on the company)</option>
                <option value="7">Mental Health Services</option>
                <option value="8">Legal Services</option>
                <option value="9">Financial Services</option>
                <option value="10">Community Services</option>
                <option value="11">Religious Organizations</option>
                <option value="12">Fitness and Wellness</option>
                <option value="13">Personal Care Services</option>
                <option value="14">Healthcare (Dental)</option>
                <option value="15">Automotive Services</option>
                <option value="16">Real Estate/Property Management</option>
                <option value="17">Retail</option>
                <option value="18">Postal Services</option>
                <option value="19">Library Services/Information Management</option>
                <option value="20">Engineering</option>
                <option value="21">Arts and Entertainment</option>
                <option value="22">Sports and Athletics</option>
                <option value="23">Food Services/Hospitality</option>
                <option value="24">Law Enforcement</option>
                <option value="25">Emergency Services</option>
                <option value="26">Accounting/Financial Services</option>
                <option value="27">Scientific Research</option>
                <option value="28">Publishing/Media</option>
                <option value="29">Business/Entrepreneurship</option>
                <option value="30">Sales</option>
                <option value="31">Government/Politics</option>
                <option value="32">Media/Journalism</option>
                <option value="33">Agriculture</option>
                <option value="34">Automotive/Mechanical Services</option>
                <option value="35">Aviation/Transportation</option>
                <option value="36">Psychology</option>
                <option value="37">Architecture/Construction</option>
              </select>
            </div>
            <div>
              <select required value={model} onChange={(e) => setModel(e.target.value)}>
                <option  >- AI Model -</option>
                <option value="1">ChatGPT 3.5</option>
                <option value="2">ChatGPT 4o</option>
                <option disabled value="Cerebras (Coming soon)">Cerebras (Coming soon)</option>
                <option disabled value="GROK (Coming soon)">GROK (Coming soon)</option>
                <option disabled value="Llama (Coming soon)">Llama (Coming soon)</option>
                <option disabled value="Claude 3.5 (Coming soon)">Claude 3.5 (Coming soon)</option>
                <option disabled value="More models coming soon">More models coming soon</option>
              </select>
            </div>
            <div>
              <input
                required
                type='text'
                placeholder='Agent name'
                name='agent-name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <textarea value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder='What should your Agent help you with'></textarea>
            </div>
            <button type='submit' className='create-agent-btn' disabled={loading}>
              {loading ? 'Creating...' : 'Create'}
              {!loading && (
                <img className='icon-create' src='https://www.svgrepo.com/show/376370/arrow-right-line.svg' alt='logo'></img>
              )}
            </button>
          </div>
        </form>
        {loading && (
          <div className="spinner-border-text">
            <div className="spinner-border" role="status"></div>
          </div>
        )}
        {message && <p>{message}</p>}
        <div className='footer'>
          <hr className='footer-divider' />
          <p className='footer-infor'>© 2024 ⎈+V</p>
        </div>
      </div>
    </div >
  );
}

export default CreateAgent;
