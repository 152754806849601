import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Register.css';

function Register({ setUsername }) {
  const [username, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [passport, setPassport] = useState(null)
  const [driversLicense, setDriversLicense] = useState(null)
  const [selfie, setSelfie] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [passportFilename, setPassportFilename] = useState('');
  const [driversLicenseFilename, setDriversLicenseFilename] = useState('');
  const [selfieFilename, setSelfieFilename] = useState('');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('name', name);
    formData.append('email', email);
    if (address) {
      formData.append('address', address);
    }
    if (passport) {
      formData.append('passport', passport);
    }
    if (driversLicense) {
      formData.append('drivers_license', driversLicense);
    }
    if (selfie) {
      formData.append('selfie', selfie);
    }

    try {
      await axios.post(`${API_URL}/register`, formData);
      setMessage('Registration successful');
      setUsername(username);
      navigate('/login');
    } catch (error) {
      setMessage('Error registering');
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e, setter, filenameSetter) => {
    const file = e.target.files[0];
    if (file) {
      setter(file);
      filenameSetter(file.name);
    } else {
      setter(null);
      filenameSetter('');
    }
  };


  return (
    <div className="register-wrapper">
      <div className="inner-register">
        <div>
          <h1>Register</h1>
          <span>
            <p className='text-02'>
              To align with global compliance regulations, people can sign up and self-KYC to use the platform.<br />
              Users information is kept private and they are issued a digital id that can be presented on request.
            </p>
          </span>
          <span>
            <p className='text-02'>
              Problem: Individual, democratized human accountability of AI Agents and their transactions<br />
              Status: Demonstrable
            </p>
          </span>
          <hr />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="inner-form">
            <div className="field">
              <input
                required
                type="text"
                placeholder="Username (Required for Demo)"
                value={username}
                onChange={(e) => setUsernameInput(e.target.value)}
              />
            </div>
            <div className="field">
              <input
                required
                type="password"
                placeholder="Password (Required for Demo)"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="field">
              <input
                required
                type="password"
                placeholder="Confirm Password (Required for Demo)"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="field">
              <input
                required
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name-on-passport-required-for-demo"
                placeholder="Name on Passport (Required for Demo)"
              />
            </div>
            <div className="field">
              <input
                required
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="personal-or-work-email-address-required-for-demo"
                placeholder="Personal or Work Email Address (Required for Demo)"
              />
            </div>
            <div className="field">
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="physical-personal-or-work-address"
                placeholder="Physical Personal or Work Address (optional)"
              />
            </div>
            <div className="field file-input-wrapper">
              <label className="file-input-label">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setPassport, setPassportFilename)}
                  name="passport-copy"
                />
                <span className="file-input-text">{passportFilename || 'Passport Copy (optional)'}</span>
                <button className='onpen-file-btn'>
                  <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvhnyBHSKpOdMkBez1jj_VlVJbOw2doWdLWg&s' alt='logo' />
                </button>
              </label>
            </div>
            <div className="field file-input-wrapper">
              <label className="file-input-label">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setDriversLicense, setDriversLicenseFilename)}
                  name="drivers-license-copy"
                />
                <span className="file-input-text">{driversLicenseFilename || 'Drivers License Copy (optional)'}</span>
                <button className='onpen-file-btn'>
                  <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvhnyBHSKpOdMkBez1jj_VlVJbOw2doWdLWg&s' alt='logo' />
                </button>
              </label>
            </div>
            <div className="field file-input-wrapper">
              <label className="file-input-label">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, setSelfie, setSelfieFilename)}
                  name="selfie-with-passport-or-drivers-license"
                />
                <span className="file-input-text">{selfieFilename || 'Selfie with Passport or Drivers License (optional)'}</span>
                <button className='onpen-file-btn'>
                  <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvhnyBHSKpOdMkBez1jj_VlVJbOw2doWdLWg&s' alt='logo' />
                </button>
              </label>
            </div>
            <div className="action">
              <button type="submit" className="register-btn" disabled={loading}>
                Register
                <img className="icon-create" src="https://www.svgrepo.com/show/376370/arrow-right-line.svg" alt="logo" />
              </button>
              {message && <p className='error-msg'>{message}</p>}
              <p className="text-02">
                Your information is never shared with 3rd parties and is secured with the highest encryption.
              </p>
              {loading && <div className="spinner-border spinner" role="status"><span className="sr-only"></span></div>}
              <div className='footer'>
                <hr className='footer-divider' />
                <p className='footer-infor'>© 2024 ⎈+V</p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
